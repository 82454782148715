import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

export interface ToastProps {
  message: string;
  success: boolean;
}

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
})
export class ToastComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ToastProps) {}
}
